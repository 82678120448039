import React, { useState, useEffect } from "react"
import Styles from "../styles/team_profile.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import InfoItem from "../components/InfoItem"
import useDynamicPath from "../hooks/useDynamicPath";
import UserListItem from "../components/UserListItem";
import amplitude, { logEvent } from "../Analytics";
import MySwipeableViews from "../components/MySwipableViews";
import PostListOnlyGoBack from "../templates/PostListOnlyGoBack";
import { BACK_BUTTON_STR } from "../constant/const";
import { UserType } from "../types/models/User";
import { SelectButtonType } from "../types/models/SelectButton";
import client from "../apis/client";

const tabList = [
    "メンバー",
    "投稿"
]

const TeamInfoPage = ( {
    location
}: any ) => {

    const initialTabIndex = Number( location.search.split( '?tab=' )[ 1 ] ) || 0

    // const teamID = location.state ? location.state.teamID : null
    const { objectID: teamID } = useDynamicPath( location, "team_profile", "teamID" )
    // const teamID = 1
    typeof window !== "undefined" && window.history.replaceState( '', '', `${ window.location.pathname }?tab=${ initialTabIndex }` )


    const teamName = location.state ? location.state.teamName : "チーム情報"

    const headerOption = {
        headerTitle: teamName,
        leftIcon: BACK_BUTTON_STR,
    }
    const initialInfoList = [
        { name: "name", label: "チーム名", value: "" },
    ]
    const [ infoList, setInfoList ] = useState( initialInfoList )
    const [ teamSportsList, setTeamSportsList ] = useState<SelectButtonType<number>[]>( [] )
    const [ imagePath, setImagePath ] = useState( "" )
    const [ memberList, setMemberList ] = useState<UserType[]>( [] )

    useEffect( () => {
        componentDidMount()
        logEvent( amplitude, "Screen-teamProfile", { pk: teamID, web: true } )
    }, [] )


    const componentDidMount = async () => {
        try {
            const { data } = await client.get( `/api/community/${ teamID }`, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            } )
            setInfoList( infoList.map( info => ( {
                name: info.name,
                label: info.label,
                value: data[ info.name ],
            } ) ).slice() )
            setTeamSportsList( data.sports_list )
            setImagePath( data.profile_image )
            setMemberList( data.member )
        }
        catch ( error ) {
            console.log( error )
            if ( error?.response?.status === 403 ) {
                window.alert( "このチームの公開情報はありません" )
                window.history.back()
            }
        }

    }

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="TeamInfo" />
                <div className={ Styles.container }>
                    <div className={ Styles.team_image_container }>
                        <img className={ Styles.team_image } src={ imagePath } style={ { height: 400 } } />
                    </div>
                    <ul className={ Styles.info_list }>
                        { infoList.map( info =>
                            <InfoItem key={ info.label } label={ info.label } value={ info.value } />
                        ) }
                        <InfoItem
                            label="スポーツ"
                            value={ teamSportsList.map( sports =>
                                <span>{ sports.label }</span>
                            ) } />
                    </ul>
                    <div style={ { width: "100%" } }>
                        <MySwipeableViews
                            tabList={ tabList }
                            location={ location }
                            initialTabIndex={ initialTabIndex }
                            fixed={ false }>
                            <InfoItem
                                label=""
                                column={ true }
                                value={ memberList.map( member =>
                                    <UserListItem user={ member } /> ) } />
                            <PostListOnlyGoBack
                                object="team"
                                ID={ teamID } />
                        </MySwipeableViews>
                    </div>
                </div>
            </>
        </Layout>
    )
}

export default TeamInfoPage
