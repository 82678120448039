import { useEffect, useRef, useState } from "react"
import { PageProps } from "gatsby"

const TimeToWaitForApplyingScroll = 500

type s = {
    aaa: true
}

type LocationType<T> = {
    state: {
        [ key in keyof T ]: string
    }
    pathname: string
}

type a = LocationType<s>

// Pick < PageProps, 'location' >

const useDynamicPath = ( location: any, objectName: string, parameterName: string ) => {


    const locationRef = useRef( location )
    const [ waitingForApplyingScroll, setwaitingForApplyingScroll ] = useState( true );
    const waitingForApplyingScrollRef = useRef( waitingForApplyingScroll )

    useEffect( () => {
        locationRef.current = location
    }, [ location ] )

    useEffect( () => {
        waitingForApplyingScrollRef.current = waitingForApplyingScroll
    }, [ waitingForApplyingScroll ] )

    useEffect( () => {
        setTimeout( () => setwaitingForApplyingScroll( false ), TimeToWaitForApplyingScroll )
    }, [] )

    useEffect( () => {
        if ( typeof window === 'undefined' ) return
        if ( !objectID ) return
        const queryParameter = window.location.href.split( '?' ).slice( -1 )[ 0 ]
        window.location.href.includes( '?' ) ?
            window.history.replaceState( '', '', `/${ objectName }/${ objectID }/?${ queryParameter }` ) :
            window.history.replaceState( '', '', `/${ objectName }/${ objectID }/` )
        // document.documentElement.scrollTop = 0
        !waitingForApplyingScrollRef.current &&
            setTimeout( () => {
                if ( objectName === "user_profile" ) return
                if ( !waitingForApplyingScrollRef.current ) {
                    document.documentElement.scrollTop = 0
                }
            }, TimeToWaitForApplyingScroll )
    }, [ location ] )

    useEffect( () => {
        window.addEventListener( 'popstate', applyPreviousScrollPosition );
        window.addEventListener( 'scroll', saveScrollPosition, { passive: true } )
        return () => {
            window.removeEventListener( 'popstate', applyPreviousScrollPosition )
            window.removeEventListener( 'scroll', saveScrollPosition )
        }
    }, [] )

    const applyPreviousScrollPosition = () => {
        if ( objectName === "user_profile" ) return
        setwaitingForApplyingScroll( true )
        setTimeout( () => {
            const pageKey = window.location.pathname
            const scroll = sessionStorage.getItem( `${ pageKey }` )
            if ( scroll !== null ) document.documentElement.scrollTop = JSON.parse( scroll )
            setwaitingForApplyingScroll( false )
        }, 1000 )
    }

    const saveScrollPosition = () => {
        const pageKey = window.location.pathname
        const scroll = document.documentElement.scrollTop
        objectID && !waitingForApplyingScrollRef.current && sessionStorage.setItem( pageKey, JSON.stringify( scroll ) )
    }

    const getObjectID = ( location: any ) => {
        if ( location.state && location.state[ parameterName ] ) {
            return location.state[ parameterName ]
        }
        else {
            return location.pathname ?
                location.pathname.split( `${ objectName }/` ).slice( -1 )[ 0 ].split( "/" )[ 0 ] :
                null
        }
    }

    const objectID = getObjectID( location )

    return {
        objectID,
        waitingForApplyingScroll
    }

}

export default useDynamicPath
