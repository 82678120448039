import React, { useState, useEffect, useRef, FC } from "react"
import Styles from "../styles/user_profile.module.scss";
import axios from "axios";
import PostItem from "../components/postItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { getInitialStateFromSessionStorage } from "../utils/getInitialStateFromSessionStorage"
import { PostTimeLineType } from "../types/models/Post";
import client from "../apis/client";


type Props = {
    object: "team",
    ID: string
}

const PostListOnlyGoBack: FC<Props> = ( {
    object,
    ID
} ) => {

    const [ postList, setUserPostList ] = useState<PostTimeLineType[]>( getInitialStateFromSessionStorage( `postList_${ object }:${ ID }`, [] ) )
    const [ nextPostURL, setNextPostURL ] = useState<string | null>( getInitialStateFromSessionStorage( `nextPostURL_${ object }:${ ID }`, null ) )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ refreshing, setRefreshing ] = useState( false )

    const postListRef = useRef( postList )
    const nextPostURLRef = useRef( nextPostURL )


    useEffect( () => {
        postListRef.current = postList
        sessionStorage.setItem( `postList_${ object }:${ ID }`, JSON.stringify( postList ) )
    }, [ postList ] )

    useEffect( () => {
        nextPostURLRef.current = nextPostURL
        sessionStorage.setItem( `nextPostURL_${ object }:${ ID }`, JSON.stringify( nextPostURL ) )
    }, [ nextPostURL ] )


    const initialFetchPostList = async () => {
        if ( postList.length !== 0 ) {
            setFetchAtLeastOnce( true )
            return
        }
        await client.get( `/api/${ object }/${ ID }/post/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        } ).then( async response_post => {
            const post_data = await response_post.data;
            setUserPostList( post_data.results )
            setNextPostURL( post_data.next )
        } )
            .catch( error => console.log( error ) )

    }

    useEffect( () => {
        initialFetchPostList( )
    }, [] )

    const goBack = async () => {
        if ( !nextPostURLRef.current ) { return }
        setGoingBack( true )
        await client.get( nextPostURLRef.current, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            withCredentials: true,
        } ).then( response => {
            const additional_post = response.data.results
            let newUserPostList = postListRef.current.concat( additional_post )
            setUserPostList( newUserPostList.slice() )
            setNextPostURL( response.data.next )
        } ).catch( error => {
            console.log( { error } )
        } );

        setTimeout( () => setGoingBack( false ), 500 )

    }

    const refresh = () => {

    }

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document === "undefined" ?
            null :
            document.querySelectorAll( "div[data-swipeable=true]" )[ 1 ] as HTMLElement,
        goBack,
        refresh,
        goingBack,
        refreshing
    } )

    return (
        <div
            className={ Styles.container }
            { ...infiniteScroll }>
            <div className={ Styles.user_post_container }>
                { fetchAtLeastOnce && postList.length === 0 &&
                    <p style={ {
                        fontSize: 24,
                        color: "rgba(0,0,0,0.54)",
                        textAlign: "center",
                        width: "100%",
                        padding: "12px 24px",
                        lineHeight: 1.4
                    } }>
                        まだ公開されている投稿はありません
                        </p>
                }
                { postListRef.current.map( post =>
                    <PostItem post={ post } key={ post.pk } />
                ) }
                { goingBack && <ActivityIndicator /> }
            </div>
        </div>
    );
}

export default PostListOnlyGoBack
