import React, { useState, useEffect, FC } from "react"
import Styles from "../styles/ActionSheet.module.scss";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";

type Props = {
    menuList: ActionSheetMenuType[],
    visible: boolean,
    hideActionSheet: () => void
}

const ActionSheet: FC<Props> = ( {
    menuList,
    visible,
    hideActionSheet
} ) => {

    const [ touched, setTouched ] = useState( false )
    const [ mouseDown, setMouseDown ] = useState( false )

    useEffect( () => {
        !visible && setTouched( false ) && setMouseDown( false )
        visible && setTimeout( () => setTouched( true ), 500 )
    }, [ visible ] )


    const renderMenuItem = () =>
        menuList.map( (option,index) =>
            <li key={ index}
                onMouseDown={ () => {
                    touched && setMouseDown( true )
                } }
                onMouseUp={ () => {
                    if ( touched && mouseDown ) {
                        option.func()
                        setMouseDown( false )
                    }
                } }
                onTouchStart={ () => { setTouched( true ) } }>
                <div style={ option.color ?
                    { color: option.color, whiteSpace: "pre-line", lineHeight: 1.5 } :
                    { whiteSpace: "pre-line", lineHeight: 1.5 } }>
                    { option.name }
                </div>
            </li> )

    const hideSheet = usePreventOnlyTouchEnd( () => hideActionSheet(), visible )

    return (
        <div
            className={ `${ Styles.container } ${ visible && Styles.show }` }
            { ...hideSheet }>
            <ul className={ Styles.menu_list }>
                { renderMenuItem() }
            </ul>
            <ul className={ Styles.cancel_item_wrapper }>
                <li    { ...hideSheet }>
                    <div>
                        キャンセル
                    </div>
                </li>
            </ul>
        </div>
    )
}

const usePreventOnlyTouchEnd = ( callback: () => void, visible: boolean ) => {

    const [ touched, setTouched ] = useState( false )
    const [ mouseDown, setMouseDown ] = useState( false )

    useEffect( () => {
        !visible && setTouched( false ) && setMouseDown( false )
        visible && setTimeout( () => setTouched( true ), 500 )
    }, [ visible ] )

    // useEffect(()=>{
    // 	touched&&alert(touched)
    // },[touched])

    const onTouchStart = () => {
        setTouched( true )
    }
    const onMouseDown = () => {
        touched && setMouseDown( true )
    }
    // const onTouchEnd=()=>{
    // 	if(touched){
    // 		callback()
    // 		setTouched(false)
    // 	}
    // }
    const onMouseUp = () => {
        if ( touched && mouseDown ) {
            callback()
            setMouseDown( false )
        }
    }

    // return { onTouchStart,  onTouchEnd,  } 
    // return { onTouchStart, onMouseDown,  onTouchEnd, onMouseUp } 
    return { onTouchStart, onMouseDown, onMouseUp }
}


export default ActionSheet
