import React, { FC, CSSProperties } from 'react'
import { BiDotsHorizontalRounded } from "react-icons/bi"

type Props = {
    style?: CSSProperties,
    className?: string
    onClick?:()=>void
}

export const HorizontalDotsIcon: FC<Props> = ( { onClick, className, style } ) =>
    <BiDotsHorizontalRounded className={ className } style={ style } onClick={ () => onClick && onClick()}/>
