import React, { FC } from "react"
import Styles from "../styles/InfoItem.module.scss";

type Props = {
    label: string,
    value: string | number|JSX.Element[],
    column?: boolean
}

const InfoItem: FC<Props> = ( {
    label,
    value,
    column = false
} ) =>
    <li className={ Styles.info_item } style={ column ? { flexDirection: "column", alignItems: "flex-start" } : {} }>
        <span className={ Styles.item_label } style={ column ? { display: "block", marginBottom: 16 } : {} }>
            { label }
        </span>
        { column ?
            <ul className={ Styles.input_wrapper } style={ { flexDirection: "column" } }>
                { value }
            </ul> :
            <ul>
                { value }
            </ul>
        }
    </li>

export default InfoItem
