import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { Link } from "gatsby"
import Styles from "../styles/UserListItem.module.scss";
import UserAvatar from "../components/UserAvatar";
import clsx from "clsx"
import { UserType } from "../types/models/User";
import { HorizontalDotsIcon } from "../icons/HorizontalDotsIcon";
import ActionSheet from "./ActionSheet";
import { ActionSheetMenuType } from "../types/models/ActionSheetMenu";


type Props = {
    user: UserType
    menuFunctions?: {
        removeUserFromAdmin: ( userUuid: string ) => void
        addUserToAdmin: ( userUuid: string ) => void
        removeMemberFromTeam: ( userUuid: string ) => void
    }
}

const UserListItem: FC<Props> = ( {
    user,
    menuFunctions
} ) => {

    const [ userMenuVisible, setUserMenuVisible ] = useState( false );

    const createMenuList = (): ActionSheetMenuType[] => {
        if ( !menuFunctions ) return []
        const menuList = [
            user.is_admin_user ?
                { name: "この人を管理者から外す", func: () => menuFunctions.removeUserFromAdmin( user.pk ) } :
                { name: "この人を管理者にする", func: () => menuFunctions.addUserToAdmin( user.pk ) },
            { name: "この人を引退させる", func: () => menuFunctions.removeMemberFromTeam( user.pk ) },
        ] as ActionSheetMenuType[]
        return menuList
    }

    const menuList: ActionSheetMenuType[] = useMemo( () =>
        menuFunctions ?
            createMenuList() :
            []
        , [ menuFunctions ] )

    return (
        <li className={ Styles.container }>
            <Link to="/user_profile" state={ { userName: user.name, userID: user.pk } }>
                <UserAvatar
                    userID={ user.pk }
                    userName={ user.name }
                    profileImageURL={ user.profile_image_100 } />
                <div className={ Styles.user_info_container }>
                    <span className={ Styles.user_name }>
                        { user.name }
                        { user.is_admin_user && <span className={ Styles.admin_user }>管理者</span> }
                    </span>
                    <p className={ Styles.status_message }>{ user.status_message }</p>
                </div>
            </Link>
            { menuFunctions &&
                <HorizontalDotsIcon
                    className={ Styles.dots_icon }
                    onClick={ () => setUserMenuVisible( true ) } /> }
            <ActionSheet
                menuList={ menuList }
                visible={ userMenuVisible }
                hideActionSheet={ () => setUserMenuVisible( false ) } />
        </li> )
}

type UserSelectListItemProps = {
    user: UserType,
    onClick: ( user: UserType ) => void,
    isFocused: boolean,
    setListItemHeight: ( height: number ) => void
    isAdminUser?: boolean
}

export const UserSelectListItem: FC<UserSelectListItemProps> = ( {
    user,
    onClick,
    isFocused,
    setListItemHeight,
    isAdminUser = false
} ) => {

    const containerRef = useRef<HTMLLIElement>( null )

    useEffect( () => {
        if ( !setListItemHeight || !containerRef.current ) return
        const height = containerRef.current.getBoundingClientRect().height
        height && setListItemHeight( height )
    }, [ isFocused ] )

    return (
        <li
            className={ clsx( Styles.select_item_container, isFocused && Styles.focused_container ) }
            ref={ containerRef }
            onClick={ () => onClick( user ) }>
            <div className={ Styles.user_select_item_content }>
                <UserAvatar
                    style={ { height: 32, width: 32 } }
                    size={ 32 }
                    avatarSize={ 16 }
                    userID={ user.pk }
                    userName={ user.name }
                    profileImageURL={ user.profile_image_100 } />
                <div className={ Styles.user_info_container }>
                    <span className={ Styles.user_name }>{ user.name }</span>
                </div>
            </div>
        </li> )
}

export default UserListItem
